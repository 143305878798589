<template>
  <div class="details">
    <top-nav :topNavData="topNavData" />
    <video
      class="video width100"
      ref="audios"
      id="audios"
      poster="https://image.iriding.cc/video/c2.png"
      x5-video-player-type="h5-page"
      webkit-playsinline="true"
      playsinline="true"
      disablePictureInPicture
      muted
      controls
      controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
    >
      <source src="https://image.iriding.cc/video/c2a.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div class="imgone height1200 marginL50 marginR50" id="brief">
      <span class="bigTextm marginT100 color0" v-html="$t('C2.v16m')"> </span>
      <!-- <span class="bigTextm color0"> {{ $t("C2.v17") }} </span><br /> -->
      <span class="smTextm marginT46 color3A" v-html="$t('C2.v18')"></span>
    </div>
    <div
      style="display: flex; align-items: center; justify-content: center"
      class="textaC font35 fontBold backgm colorW"
    >
      <span
        style="display: inline-block; padding: 0.8rem 0 0.8rem 0"
        v-html="$t('C2.v19m')"
      ></span>
    </div>
    <template v-for="(item, index) in content1">
      <div
        class="height1200 positionR"
        :style="{
          backgroundImage: 'url(' + item.img + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        :key="'content1' + index"
      >
        <div class="item1 marginL50 colorW marginR50" v-if="index === 0">
          <span class="bigTextm marginT100" v-html="$t(item.text1)"></span>
          <br />
          <span class="smTextm marginT30" v-html="$t(item.text2)"></span><br />
          <span
            style="bottom: 0.25rem"
            class="font20 in-block fw400 positionA color4e marginR50"
          >
            {{ $t("C2.v22") }}</span
          >
        </div>
        <div class="item1 marginL50 colorW marginR50" v-if="index === 1">
          <span class="bigTextm marginT100" v-html="$t(item.text1)"></span>
          <br />
          <span
            style="position: relative"
            class="smTextm marginT30"
            v-html="$t(item.text2)"
          >
            <!-- <sup
              style="
                position: absolute;
                margin-top: -0.08rem;
                margin-left: -0.15rem;
              "
              class="font15"
              >「1」</sup
            >{{ $t(item.text3) }}{{ $t(item.text4) }} -->
          </span>
        </div>
        <div
          class="item1 marginL50 marginR50 positionR height100"
          v-if="index === 2"
        >
          <span
            style="margin-top: 0.75rem"
            class="bigTextm color0"
            v-html="$t(item.text1)"
          ></span>
          <br />
          <span style="margin-top: 0.2rem" class="smTextm color3A positionR"
            >{{ $t(item.text2) }}{{ $t(item.text3) }}
            <sup
              class="font15 color3A"
              style="position: absolute; margin-left: -0.1rem; margin-top: 0"
              >「2」</sup
            >
          </span>
          <br />
          <span
            style="bottom: 0.53rem"
            class="font20 fw400 positionA color4e"
            >{{ $t("C2.v26c") }}</span
          >
        </div>
      </div>
    </template>
    <div class="backgm paddingB30">
      <span
        style="display: inline-block"
        class="textaC font35 fw400 width100 marginT80 colorW"
        v-html="$t('C2.v27b')"
      ></span>
      <div
        class="backgm-item1 paddingB30 backgW marginT80 marginL25 marginR25 borR40"
      >
        <span class="color0 bigTextm marginL50 marginT80">{{
          $t("C2.v28")
        }}</span
        ><br />
        <span class="color0 bigTextm marginL50">{{ $t("C2.v29") }}</span
        ><br />
        <span
          class="smTextm marginL50 marginT30 color3A marginR50"
          v-html="$t('C2.v30')"
        ></span>
        <div
          style="height: 9.6rem"
          class="inner-imgm marginL25 marginR25 borR25"
        >
          <span
            class="colorW width100 fw400 in-block font30 textaC marginT46"
            >{{ $t("C2.m31") }}</span
          >
          <img
            style="
              width: 4.98rem;
              height: 3.53rem;
              margin-top: 0.4rem;
              margin-left: 0.7rem;
            "
            src="@/assets/image/c2phone/black-b.png"
            alt=""
          />
          <!-- <ul
            class="ul1"
            style="
              margin-bottom: 0.5rem;
              display: flex;
              justify-content: space-around;
              margin: 0.47rem 0.8rem 0.67rem 0.8rem;
            "
          >
            <li
              class="dispF"
              style="width: 50%"
              :style="index == 1 ? 'margin-left:unset;' : ''"
              v-for="(item, index) in ul.ul1"
              :key="'ul1' + index"
            >
              <img :class="'img' + index" :src="item.img" alt="" />
              <span class="font20 fw400">{{ $t(item.text1) }}</span>
              <span class="font20 fw400">{{ $t(item.text2) }}</span>
            </li>
            <br style="clear: both" />
          </ul> -->
          <ul class="ul2" style="margin-top: 1.5rem">
            <li
              style="padding-bottom: 0.35rem; margin-left: 0.4rem; float: unset"
              v-for="(item, index) in ul.ul2"
              :key="'ul2' + index"
            >
              <div>
                <img :class="'img' + index" :src="item.img" alt="" />
              </div>
              <span class="font24 fw400">{{ $t(item.text1) }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="backgm-item2 backgW marginT30 borR40 marginL25 marginR25">
        <div class="marginL50 marginR50">
          <span class="color0 bigTextm marginT80" v-html="$t('C2.v39')"></span
          ><br />
          <span class="smTextm marginT30 color3A" v-html="$t('C2.v40m')">
          </span>
          <div style="display: flex">
            <div style="margin-top: 0.4rem" class="item1 floatL disp">
              <span class="font24 color567">{{ $t("C2.v41") }}</span>
              <div
                style="margin-left: 0.05rem; display: flex; height: 100%"
                :style="isIos ? 'margin-top:0.2rem' : 'margin-top:0.2rem'"
              >
                <img src="@/assets/image/c2phone/p-icon1.png" alt="" />
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.4rem;
                margin-left: 0.2rem;
              "
              class="item1 floatL"
            >
              <span class="font24 colorA18">{{ $t("C2.v42") }}</span>
              <div
                :style="isIos ? 'margin-top:0.2rem' : 'margin-top:0.2rem'"
                style="display: flex; margin-left: 0.05rem; height: 100%"
              >
                <img src="@/assets/image/c2phone/p-icon2.png" alt="" />
              </div>
            </div>

            <br style="clear: both" />
          </div>
          <ul style="text-align: center" class="ul1">
            <li
              style="width: 30%; margin-bottom: 0.38rem"
              class="font24"
              v-for="(item, index) in ul.ul3"
              :key="'ul3' + index"
              :style="index !== 2 ? 'margin-right:0.2rem' : ''"
            >
              <img :src="item.img" alt="" />
              <span>{{ $t(item.text1) }}</span>
              <span>{{ $t(item.text2) }}</span>
            </li>
            <br style="clear: both" />
          </ul>
        </div>
      </div>
      <div class="backgm-item3M backitemC color0">
        <span
          class="bigTextm marginL50 marginR50 marginT80"
          v-html="$t('C2.v50')"
        ></span>
        <span class="smTextm marginL50 marginR50 marginT30">{{
          $t("C2.v51")
        }}</span>
      </div>
      <div class="backgm-item4 backitemC marginT30">
        <span class="color0 bigTextm marginL50 marginT80">{{
          $t("C2.v52b")
        }}</span>
        <span class="smTextm marginT30 color3A marginL50 marginT80 marginR50">{{
          $t("C2.v53")
        }}</span>
      </div>
      <span class="bigTextm width100 marginT50 textaC colorW">{{
        $t("C2.v55")
      }}</span>
      <br />
      <span class="bigTextm width100 marginB50 textaC colorW">{{
        $t("C2.v56")
      }}</span>
      <div class="backgm-item5 backitemC positionR">
        <div class="marginL50 marginR50 height100">
          <span
            class="color0 bigTextm width100 marginT80"
            v-html="$t('C2.v57m')"
          ></span>
          <!-- <br />
          <span class="color0 bigTextm width100 marginB50">{{
            $t("C2.v57a")
          }}</span
          > -->
          <br />
          <span
            style="margin-top: 0.3rem"
            class="smTextm color3A"
            v-html="$t('C2.v58m')"
          >
          </span
          ><br />
          <span
            style="right: 0.5rem; left: 0.5rem; font-size: 0.24rem"
            class="smTextm color4e positionA bottom47"
            >{{ $t("C2.v59") }}{{ $t("C2.v60") }}</span
          >
        </div>
      </div>
      <div class="backgm-item6 positionR backgW marginT30">
        <div style="padding-top: 0.8rem" class="marginL50 marginR50">
          <span style="display: block" class="color0 bigTextm positionR"
            >{{ $t("C2.v61") }}
            <sup style="margin-left: -0.05rem" class="font15 positionA color3A"
              >「4」</sup
            >
          </span>
          <span class="smTextm color3A marginT30">{{ $t("C2.v62") }}</span>
          <div
            :class="index === 0 ? 'top1' : 'top2'"
            v-for="(item, index) in ul.ul4"
            :key="'ul4' + index"
          >
            <div class="item1">
              <div class="item-img">
                <img :class="'img1' + index" :src="item.img1" alt="" />
              </div>
              <div class="item-img">
                <img :class="'img2' + index" :src="item.img2" alt="" />
              </div>
            </div>
            <div class="item2 font24">
              <div class="item-text color3A">
                <div style="padding-right: 0.2rem">{{ $t(item.text1) }}</div>
              </div>
              <div class="item-text color3A">
                <div style="padding-left: 0.2rem">{{ $t(item.text2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          background-image: unset;
          background: #fff;
          border-radius: 0.4rem;
          height: unset;
        "
        class="backgm-item7 backitemC marginT30"
      >
        <div class="marginL50 marginR50">
          <span class="color0 bigTextm marginT80">{{ $t("C2.v75") }} </span
          ><br />
          <span class="smTextm color3A marginT30">{{ $t("C2.v76") }}</span
          ><br />
          <span style="margin-top: 0.44rem" class="color0 bigTextm"
            >{{ $t("C2.v77") }} </span
          ><br />
          <span class="smTextm color3A marginT30"> {{ $t("C2.m78a") }}</span
          ><br />
          <span class="smTextm color3A">{{ $t("C2.m78b") }}</span>
        </div>
        <img
          style="width: 7rem;margin-top: 0.56rem;
}"
          src="@/assets/image/c2phone/blue.png"
          alt=""
        />
      </div>
      <div
        style="height: auto"
        class="backgm-item8 backgW borR40 marginL25 marginR25 marginT30"
      >
        <div class="marginL50 marginR50 positionR">
          <span
            style="display: inline-block !important"
            class="color0 bigTextm marginT80 positionR"
            v-html="$t('C2.v79a')"
          >
          </span>
          <span class="smTextm color3A marginT30"
            >{{ $t("C2.v80") }}{{ $t("C2.v81") }}</span
          ><br />
          <span style="margin-bottom: 0.25rem" class="smTextm color3A"
            >{{ $t("C2.v82") }}{{ $t("C2.v83") }}{{ $t("C2.v84") }}</span
          >
          <img src="@/assets/image/c2phone/t-1.png" alt="" />
          <img class="margin" src="@/assets/image/c2phone/t-2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      style="background: #3a3a3a; width: 100%; padding: 0.5rem 0 0.5rem 0"
      class="positionR"
    >
      <div
        style="
          background: #fff;
          border-radius: 0.4rem;
          margin: 0 0.25rem 0 0.25rem;
          padding-top: 0.9rem;
        "
      >
        <div style="margin: 0 0.5rem 0 0.5rem">
          <span style="display: block" class="color0 bigTextm"
            >{{ $t("C2.v85m") }}
            <sup
              style="position: absolute; margin-left: -0.05rem"
              class="font15 color3A"
              >「6」</sup
            ></span
          >
          <!-- <span class="color0 bigTextm">{{ $t("C2.v85a") }}</span
          > -->
          <!-- <br /> -->
          <span class="smTextm color3A marginT30"
            >{{ $t("C2.m86a") }}{{ $t("C2.v86b") }}</span
          >
        </div>
        <img
          style="width: 7rem; border-radius: 0.4rem"
          src="@/assets/image/c2phone/new-p.png"
          alt=""
        />
      </div>
    </div>
    <div class="backgm-item10 height1200">
      <div class="marginL50 marginR50">
        <span class="color0 bigTextm marginT100">{{ $t("C2.v88") }}</span
        ><br />
        <span class="color0 bigTextm">{{ $t("C2.v89") }}</span
        ><br />
        <span class="smTextm marginT30 color3A">{{ $t("C2.m90a") }}</span
        ><br />
        <img
          class="img2 marginT100"
          src="@/assets/image/c2phone/img13.png"
          alt=""
        />
      </div>
    </div>
    <div class="backgm-item11">
      <div
        style="padding: 1rem 0.8rem 1rem 0.8rem; background: #404349"
        class="font35 fw400 textaC colorW"
      >
        {{ $t("C2.v93") }}
      </div>
      <template v-for="(item, index) in imgM">
        <div
          class="text item backF5 font27"
          v-if="item.text1 && item.text1 !== ''"
          :key="'text' + index"
        >
          <div style="margin: 0 0.3rem 0 0.3rem">
            <span class="positionR">
              {{ $t(item.text1) }}
              <sup
                class="font15 positionA color3A"
                :style="
                  isIos
                    ? 'margin: -0.05rem 0 0 -0.17rem'
                    : 'margin: -0.05rem 0 0 -0.13rem'
                "
                v-if="index === 8"
                >「7」
              </sup>
              <sup
                class="font15 positionA color3A"
                :style="
                  isIos
                    ? ios13
                      ? 'margin: -0.07rem 0 0 -0.2rem'
                      : 'margin: -0.5rem 0 0 2.8rem'
                    : 'margin: -0.06rem 0 0 -0.13rem'
                "
                v-if="index === 11"
                :class="ios15 ? (ises ? 'es' : isko ? 'ko' : '') : ''"
                >「8」</sup
              >
            </span>
            <br />
            <span>{{ $t(item.text2) }}</span>
          </div>
        </div>
        <div class="img item" v-else :key="'img' + index">
          <img :src="item.img" alt="" />
        </div>
      </template>
      <br style="clear: both" />
    </div>
    <div class="refer font24 color3A backF5 positionR">
      <div style="margin-left: 0.3rem">
        <div
          v-for="(item, index) in refer"
          :key="'refer' + index"
          style="margin: unset"
          :style="index === 8 ? 'margin-top:0.4rem' : ''"
          class="positionR"
        >
          <span
            style="
              line-height: 25px;
              display: inline-block;
              text-indent: -0.39rem;
              margin: 0 0.5rem 0 0.5rem;
              word-break: break-all;
            "
          >
            {{ $t(item) }}
          </span>
        </div>
      </div>
    </div>
    <div class="de" id="de"></div>
    <div class="info font24">
      <span
        style="margin-top: 0.83rem; font-size: 0.3rem"
        class="smTextm color3A width100 textaC"
      >
        {{ $t("C2.v120") }}
      </span>
      <img src="@/assets/image/c2phone/1530mm.png" alt="" />
      <div
        :class="index !== 15 ? 'border' : ''"
        v-for="(item, index) in info"
        :key="'info' + index"
        style="
          height: auto;
          display: flex;

          flex-direction: column;
          line-height: 0.35rem;
        "
        :style="
          index === 15
            ? 'padding: 0.3rem 0 0.5rem 0;'
            : 'padding: 0.3rem 0 0.3rem 0;'
        "
      >
        <span class="color0">{{ $t(item.text1) }}</span>
        <span class="color58">{{ $t(item.text2) }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="title font47 border p06 fontBold width100">
        {{ $t(footerData.title) }}
      </div>
      <div
        :class="index === 4 ? 'bottom69' : ''"
        class="mainItem"
        v-for="(item, index) in footerData.footer"
        :key="'footer' + index"
      >
        <div class="itemC2">
          <div>
            <img
              style="margin-bottom: 0.5rem"
              :src="item.img1"
              :class="'img1' + index"
              alt=""
            />
          </div>
          <div v-if="item.text2">
            <img
              style="margin-bottom: 0.5rem"
              :src="item.img2"
              :class="'img2' + index"
              alt=""
            />
          </div>
        </div>
        <div class="item2 font24 color58">
          <div
            :style="
              item.text3 && item.text3 !== '' ? 'flex-direction: column;' : ''
            "
          >
            <span class="textaC fw400" v-html="$t(item.text1)"></span>
            <span
              style="text-align: center"
              v-if="item.text3 && item.text3 !== ''"
              >{{ $t(item.text3) }}</span
            >
          </div>
          <div v-if="item.text2">
            <span class="textaC fw400">{{ $t(item.text2) }}</span>
          </div>
        </div>
      </div>
    </div>
    <to-top />
  </div>
</template>

<script>
import TopNav from "../../component/product/topNav.vue";
import ToTop from "../../component/totop/toTop.vue";
//import Footer from "../../component/product/footer.vue";
import util from "@/util.js";
import methods from "@/assets/js/methods";
export default {
  name: "c2phone",
  components: {
    TopNav,
    ToTop,
    //Footer,
  },
  data() {
    return {
      hh: false,
      topNavData: {
        showMenu: false,
        isShow: false,
        num: 0,
        type: "C2",
        img1: require("@/assets/image/c2phone/img1.png"),
        title: "C2.v1",
        text: "C2.v2",
        title2: "C2.v1m",
      },
      footerData: {
        title: "C2.v153",
        type: "C2",
        footer: [
          {
            img1: require("@/assets/image/c2phone/icon-footer1.png"),
            text1: "C2.v154",
            img2: require("@/assets/image/c2phone/icon-footer2.png"),
            text2: "C2.v160",
          },
          {
            img1: require("@/assets/image/c2phone/icon-footer3.png"),
            text1: "C2.v155",
            img2: require("@/assets/image/c2phone/icon-footer5.png"),
            text2: "C2.v156",
          },
          {
            img1: require("@/assets/image/c2phone/icon-footer6.png"),
            text1: "C2.v157",
            img2: require("@/assets/image/c2phone/icon-footer9.png"),
            text2: "C2.v159",
          },
        ],
      },
      content1: [
        {
          img: require("@/assets/image/c2phone/img4.png"),
          text1: "C2.v20",
          text2: "C2.v21",
          text3: "C2.v22",
        },
        {
          img: require("@/assets/image/c2phone/img5.png"),
          text1: "C2.v23",
          text2: "C2.v24c",
        },
        {
          img: require("@/assets/image/c2phone/img6.png"),
          text1: "C2.v25m",
          text2: "C2.m26a",
          text3: "C2.m26b",
          text4: "C2.v26d",
        },
      ],
      ul: {
        ul1: [
          {
            img: require("@/assets/image/c2phone/black-icon1.png"),
            text1: "C2.v31",
            text2: "C2.v32",
          },
          {
            img: require("@/assets/image/c2phone/black-icon2.png"),
            text1: "C2.v33",
            text2: "C2.v34",
          },
        ],
        ul2: [
          {
            img: require("@/assets/image/c2phone/black-icon3.png"),
            text1: "C2.v35",
          },
          {
            img: require("@/assets/image/c2phone/black-icon4.png"),
            text1: "C2.v36",
          },
          {
            img: require("@/assets/image/c2phone/black-icon5.png"),
            text1: "C2.v37",
          },
          {
            img: require("@/assets/image/c2phone/black-icon6.png"),
            text1: "C2.v38",
          },
        ],
        ul3: [
          {
            img: require("@/assets/image/c2phone/pm-25.png"),
            text1: "C2.v44",
            text2: "C2.v45",
          },
          {
            img: require("@/assets/image/c2phone/pm-50.png"),
            text1: "C2.v46",
            text2: "C2.v47",
          },
          {
            img: require("@/assets/image/c2phone/pm-75.png"),
            text1: "C2.v48",
            text2: "C2.v49",
          },
        ],
        ul4: [
          {
            img1: require("@/assets/image/c2phone/s-icon1.png"),
            text1: "C2.v63",
            img2: require("@/assets/image/c2phone/s-icon2.png"),
            text2: "C2.v64",
          },
          {
            img1: require("@/assets/image/c2phone/s-icon3.png"),
            text1: "C2.v67",
            img2: require("@/assets/image/c2phone/s-icon4.png"),
            text2: "C2.v68",
          },
          {
            img1: require("@/assets/image/c2phone/s-icon5.png"),
            text1: "C2.v71",
            img2: require("@/assets/image/c2phone/s-icon6.png"),
            text2: "C2.v72",
          },
          {
            img1: require("@/assets/image/c2phone/s-icon7.png"),
            text1: "C2.v65",
            img2: require("@/assets/image/c2phone/s-icon8.png"),
            text2: "C2.v66",
          },
          {
            img1: require("@/assets/image/c2phone/s-icon9.png"),
            text1: "C2.v69",
            img2: require("@/assets/image/c2phone/s-icon10.png"),
            text2: "C2.v70",
          },
          {
            img1: require("@/assets/image/c2phone/s-icon11.png"),
            text1: "C2.v73",
            img2: require("@/assets/image/c2phone/s-icon12.png"),
            text2: "C2.v74",
          },
        ],
      },
      imgM: [
        {
          text1: "C2.v94",
          text2: "C2.v95",
        },
        {
          img: require("@/assets/image/c2phone/M-1.png"),
        },
        {
          img: require("@/assets/image/c2phone/M-2.png"),
        },
        {
          text1: "C2.v96",
          text2: "C2.v97",
        },
        {
          text1: "C2.v98",
          text2: "C2.v99",
        },
        {
          img: require("@/assets/image/c2phone/M-3.png"),
        },
        {
          img: require("@/assets/image/c2phone/M-4.png"),
        },
        {
          text1: "C2.v100",
          text2: "C2.v101",
        },
        {
          text1: "C2.v102",
          text2: "C2.v103",
        },
        {
          img: require("@/assets/image/c2phone/M-5.png"),
        },
        {
          img: require("@/assets/image/c2phone/M-6.png"),
        },
        {
          text1: "C2.v104",
          text2: "C2.v105",
        },
      ],
      info: [
        {
          text1: "C2.v121",
          text2: "",
        },
        {
          text1: "C2.v125",
          text2: "C2.v126",
        },
        {
          text1: "C2.v129",
          text2: "C2.v130",
        },
        {
          text1: "C2.v133",
          text2: "C2.v134",
        },
        {
          text1: "C2.v137",
          text2: "C2.v138",
        },
        {
          text1: "C2.v141",
          text2: "C2.v142",
        },
        {
          text1: "C2.v145",
          text2: "C2.v146",
        },
        {
          text1: "C2.v149",
          text2: "C2.v150",
        },
        {
          text1: "C2.v123",
          text2: "C2.v124",
        },
        {
          text1: "C2.v127",
          text2: "C2.v128",
        },
        {
          text1: "C2.v131",
          text2: "C2.v132",
        },
        {
          text1: "C2.v135",
          text2: "C2.v136",
        },
        {
          text1: "C2.v139",
          text2: "C2.v140",
        },
        {
          text1: "C2.v143",
          text2: "C2.v144",
        },
        {
          text1: "C2.v147",
          text2: "C2.v148",
        },
        {
          text1: "C2.v151",
          text2: "C2.v152",
        },
      ],
      refer: [
        "C2.v107",
        "C2.v108",
        "C2.v109",
        "C2.v110",
        "C2.v111",
        "C2.v112",
        "C2.v114",
        "C2.v115",
        "C2.v116",
      ],
      isIos: false,
      ios13: false,
      ises: false,
      ios15: false,
      isko: false,
      scrolldata: {
        isShow: "",
        topdistance: "",
        target: "",
      },
    };
  },
  mounted() {
    if (["es", "fr"].includes(localStorage.getItem("lans"))) {
      this.ises = true;
    }
    if (localStorage.getItem("lans") === "ko") {
      this.isko = true;
    }
    let Phonetype = util.isIos();
    if (Phonetype && Phonetype.indexOf("13_") !== -1) {
      this.isIos = true;
      this.ios13 = true;
    } else if (Phonetype && !Phonetype.indexOf("15_") !== -1) {
      this.isIos = true;
      this.ios13 = false;
      this.ios15 = true;
    }
    let timer = null;
    setTimeout(() => {
      methods.sizeadapt(750);
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        methods.sizeadapt(750);
      }, 500);
    };
    window.addEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      this.scrolldata = {
        isShow: this.topNavData.isShow,
        showMenu: this.topNavData.showMenu,
        topid: "#brief",
        target: "#de",
      };
      let data = methods.getScroll(this.scrolldata);
      this.topNavData.showMenu = data.showMenu;
      this.topNavData.isShow = data.isShow;
    },
  },
};
</script>

<style lang="less">
@import "@/assets/less/screenc2phone.less";
@import "@/assets/less/commonphone.less";
</style>
